import * as React from 'react'
import Link from 'gatsby-link'
import Layout from './layout'
import { BlogPost } from '../../graphql-types'

interface BlogPostListProps {
  posts: BlogPost[]
}

export const Posts: React.FC<BlogPostListProps> = ({ posts }) => {
  console.log(posts)
  return (
    <Layout>
      <div>
        {posts.map((post) => (
          <div key={post.id}>
            <Link activeClassName="active" to={post.slug}>
              {post.title}
            </Link>{' '}
            <span>{post.date}</span>
            <p>{post.excerpt}</p>
          </div>
        ))}
      </div>
    </Layout>
  )
}
