import { PageProps } from 'gatsby'
import React from 'react'

import { Posts } from '../components/Posts'
import { BlogPost } from '../../graphql-types'

export interface PostsPageProps {
  posts: BlogPost[]
}

export default ({
  pageContext: { posts },
}: PageProps<void, PostsPageProps>) => (
  <Posts
    posts={posts}
  />
)
